.progress {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
	background-color: rgba(0, 0, 0, 0.5);

	.container {
		position: relative;
		margin-left: auto;
		margin-right: auto;
		margin-top: 25%;
		width: 260px;
		.barre {
			background-color: snow;
			padding: 2px 2px;
			width: 206px;
			height: 20px;
			border-radius: 5px;
			border: solid Fuchsia 1px;
			margin-left: auto;
			margin-right: auto;
			margin-top: 25%;

			.part {
				display: inline-block;
				width: 2px;
				height: 14px;
				margin: 0;
				padding: 0;
				float: left;
				background-color: GreenYellow;
			}
		}
		.stop {
			display: inline-block;
			position: absolute;
			right: -20px;
			top: -12px;
		}
	}
}

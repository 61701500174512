$colorHome: #ff8c00;

@mixin nodeContent($couleur) {
	background-color: $couleur;
	&:after {
		border-left-color: $couleur;
	}

	&:hover {
		background-color: darken($couleur, 10%);
		&:after {
			border-left-color: darken($couleur, 10%);
		}
	}
}

.fil-d-ariane {
	list-style: none;
	overflow: hidden;
	font-size: 24px;
	font-weight: bold;
	height: 50px;

	@media screen and (max-width: 960px) {
		font-size: 16px;
	}

	@media screen and (max-width: 640px) {
		display: none;
	}

	.home {
		.home-icone {
			margin-left: -35px;
		}
	}

	li {
		float: left;

		@for $i from 1 through 15 {
			$count: 1 - $i / 15;
			$couleur: rgb(255 * $count, 140 * $count, 0);
			&:nth-child(#{$i}) a {
				@include nodeContent($couleur);
			}
			&:nth-child(#{$i}) i {
				@include nodeContent($couleur);
			}

			a,
			i {
				color: white;
				text-decoration: none;
				padding: 10px 0 10px 65px;
				background: $colorHome;
				position: relative;
				display: block;
				float: left;
				height: 50px;
				padding-top: 10px;

				&:after {
					content: ' ';
					display: block;
					width: 0;
					height: 0;
					border-top: 50px solid transparent;
					border-bottom: 50px solid transparent;
					border-left: 30px solid $colorHome;
					position: absolute;
					top: 50%;
					margin-top: -50px;
					left: 100%;
					z-index: 2;
				}

				&:before {
					content: ' ';
					display: block;
					width: 0;
					height: 0;
					border-top: 50px solid transparent;
					border-bottom: 50px solid transparent;
					border-left: 30px solid white;
					position: absolute;
					top: 50%;
					margin-top: -50px;
					margin-left: 1px;
					left: 100%;
					z-index: 1;
				}
			}
		}
	}
}

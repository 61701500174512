.mon-fichier {
	.status-button {
		display: flex;
		flex-direction: row-reverse;
		margin-top: 6px;
	}
	.paragraphe {
		padding: 8px 20px 18px 20px;
		margin-bottom: 8px;
	}
	.lien-telechargement {
		max-width: 500px;

		margin-top: 24px;
		margin-bottom: 12px;
	}
}

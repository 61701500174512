$primaryColor: #5C6DBA;
$secondaryColor: #fb8c00;
$cardHeader: mediumaquamarine;
$font-size-titre: 16px;
$font-size-corps: 14px;

a {
	text-decoration: none;
}

.centered {
	text-align: center;
}

.onyxia-toolbar {
	background-color: ivory !important;
	padding: 5px 20px;
	position: relative;
	margin-bottom: 12px;
	.bouton {
		margin-right: 8px;
		height: 40px;
		width: 40px;
	}
	.bouton-rouge {
		@extend .bouton;
		color: red;
	}
}

.icone-bouton {
	height: 20px;
	width: 20px;
}

@mixin shadow() {
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
		0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}

@mixin multilineEllipsis($font-size, $line-height, $lines-to-show) {
	box-sizing: border-box;
	display: block; /* Fallback for non-webkit */
	display: -webkit-box;
	max-width: 400px;
	height: $font-size * $line-height * $lines-to-show;
	/* Fallback for non-webkit */
	margin: 0 auto;
	font-size: $font-size;
	line-height: $line-height;
	-webkit-line-clamp: $lines-to-show;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

@mixin carte {
	@media screen and (max-width: 960px) {
		width: 100%;
	}
	.container {
		position: relative;
		@include paragraphe();

		.en-tete {
			background-color: $cardHeader;
			.titre {
				color: snow;
				font-size: 21px;
				font-weight: bold;
			}
			.sous-titre {
				color: gold;
				font-size: 14px;
			}
			.avatar {
				float: left;
				margin-right: 0px;
				margin-top: -6px;

				.warn {
					background-color: #facc2e;
				}
				.pause {
					background-color: gainsboro;
				}
				.running {
					background-color: chartreuse;
				}
				.down {
					background-color: red;
				}

				.etat-service {
					display: inline-block;
					width: 46px;
					height: 46px;
					padding: 2px 2px;
					border-radius: 50%;

					.bordure-carte {
						background-color: grey;
						display: inline-block;
						padding: 1px 1px;
						border-radius: 50%;
					}
				}
			}
		}
	}
}

@mixin carteService {
	.petit-avatar {
		width: 30px;
		height: 30px;
	}
	.carte {
		@include carte();

		.zoom {
			&:hover {
				width: 280px;
			}
			transition: width 2s;
			-webkit-transition: width 2s;
		}

		.container {
			.contenu {
				margin-top: 0px;
				width: auto;

				height: 116px;

				.paragraphe {
					.titre {
						font-size: $font-size-titre;
					}
					.corps {
						@include multilineEllipsis($font-size-corps, 1, 4);
					}
				}
			}
			.en-tete {
				height: 60px;
				.titre {
					@include multilineEllipsis($font-size-titre, 1, 1);
				}
				.sous-titre {
					font-size: $font-size-corps;
					height: $font-size-corps + 4px;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
		}

		.boutons {
			float: right;
		}
	}
}

@mixin paragraphe {
	.paragraphe {
		display: block;
		margin: 4px 5px;

		.titre {
			display: block;
			margin-bottom: 8px;
			font-size: 21px;
			font-weight: bold;
		}
		.collapse {
			.bouton {
				float: right;
			}
		}

		.corps {
			display: block;
			margin: 8px 0;
		}
	}
}

@mixin applicationModal {
	@include carte();
	.en-tete {
		.avatar {
			margin-left: -12px;
			margin-right: 20px !important;
		}
	}

	.container {
		.contenu {
			padding: 5px 24px;
			@include paragraphe();
		}
	}

}

/** NOTE | TODO, remove, temporary hack to give page a fixed height */
.mySecrets {
	height: calc(100vh - 64px);
	width: 100%;
}

.application {
	.snow {
		.en-tete {
			background-color: silver;
		}

		background-color: lightgray;
	}

	background-color: #EDEDED;

	.en-tete {
		display: block;
		padding: 10px;
		width: 100%;
		background-color: snow;
	}

	.contenu {
		margin-top: 20px;
		margin-left: auto;
		margin-right: auto;
		width: 1024px;
		padding: 10px 20px;
		background-color: snow;
		@include paragraphe();

		.paper {
			padding: 5px 35px;
			margin-bottom: 12px;
		}

		.logo-service {
			margin: 0 auto;
			margin-bottom: 24px;
			height: 100px;
			width: 100px;
			img {
				width: 100%;
				height: 100%;
			}
		}

		@media screen and (max-width: 1024px) {
			width: 100%;
		}
	}
}

.erreur {
	color: crimson;
	float: left;
	font-size: 24px;
	margin: -4px 3px 0 0;
}

.message-service {
	.icone {
		float: left;
		margin-right: 8px;

		.rouge {
			background-color: crimson;
			color: darkslategray;
		}
		.vert {
			background-color: chartreuse;
			color: darkslategray;
		}
		.orange {
			background-color: coral;
			color: darkslategray;
		}
	}

	.message {
		display: inline-block;
		.nom {
			font-weight: bold;
		}

		.rouge {
			color: crimson;
		}
		.vert {
			color: chartreuse;
		}
		.orange {
			color: coral;
		}
	}
}

.carte-service {
	z-index: 1;
	.pause {
		z-index: -1;
		position: absolute;
		top: 0;
		left: 0;

		background-color: #eef;
		height: 100%;
		width: 100%;
		svg {
			margin: 40% 33%;
		}
	}
}

@keyframes loading-anim {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(-359deg);
	}
}

.loading {
	animation: loading-anim 3s infinite linear;
}

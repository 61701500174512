.my-files {
	.liens {
		.entry {
			position: relative;
			display: block;
			min-height: 36px;
			.directory {
				width: 100%;
				justify-content: left;

				.texte {
					font-size: 14px;
					text-transform: none;
				}
			}
			.file {
				width: 100%;
				justify-content: left;
			}
			.icone {
				text-align: left;
				vertical-align: middle;
				margin-right: 10px;
				font-size: 24px;
			}

			.select-it {
				position: absolute;
				right: 0;
				top: -6px;
			}
		}
	}

	.upload-button {
		display: flex;
		flex-direction: row-reverse;
	}

	.paragraphe {
		padding: 8px 20px 18px 20px;
		margin-bottom: 8px;
	}
}

.upload-files {
	.file {
		display: block;
		background-color: snow;
	}
}

.mes-fichiers {
	.actions {
		.action {
			display: inline-block;
		}
	}
}

.dialogue-upload {
	.info {
		margin: 8px 1px;
	}

	.icone-bouton {
		margin-right: 5px;
	}
}

#bucket-list {
	display: inline-flex;
	flex-wrap: wrap;
	flex-direction: row;
	flex: 1;
}

#bucket-list > a {
	// .bucket-block {
	display: flex;
	border: 1px solid black;
	padding: 2em;
	margin: 2.5%;
	flex: 1 0 20%;
	box-sizing: border-box;
	flex-direction: column;
	align-items: center;
	border-radius: 5px;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.38);

	&:hover {
		box-shadow: none;
	}

	> * {
		margin: 0.5em 0;
	}

	> img {
		border-radius: 50%;
		height: 4em;
		width: 4em;
		border: 2px solid rgba(0, 0, 0, 0.38);
		object-fit: cover;
	}
}
